




import { Component, Prop, Vue } from 'vue-property-decorator'

import transakSDK from '@transak/transak-sdk'
import { getDepositAddress } from '@/clients/cpinblocks'
import type { Currency, Network } from '@/types'
import { alertRawError, env } from '@/utils'

@Component({
  components: {
  },
})
export default class Transak extends Vue {
  @Prop()
  network!: Network

  @Prop()
  currency!: Currency

  get transakNetwork () {
    if (this.network === 'BINANCE') {
      return 'BSC'
    }
  }

  async created (): Promise<void> {
    const appEnv = env('VUE_APP_ENV')

    const custodial = await getDepositAddress(this.$store.state.jwt, this.currency, this.network)
    if (custodial === null || !custodial?.address) {
      alertRawError('Error while processing your custodial address for this payment.')
      return
    }

    const transakNetwork = this.network === 'BINANCE' ? 'BSC' : null
    if (transakNetwork === null) {
      alertRawError('Cannot deal with network ' + this.network)
      return
    }

    // eslint-disable-next-line new-cap
    const transak = new transakSDK({
      zIndex: 1000,
      environment: env('VUE_APP_TRANSAK_ENV'),
      apiKey: env('VUE_APP_TRANSAK_API_KEY'),
      hostURL: window.location.origin,
      network: this.transakNetwork,
      defaultFiatAmount: 100,
      widgetHeight: '625px',
      widgetWidth: '500px',
      cryptoCurrencyCode: this.currency,
      walletAddress: custodial.address,
      themeColor: '#263F4C',
      fiatCurrency: 'EUR',
      paymentMethod: 'credit_debit_card',
    })

    transak.init()

    // To get all the events
    transak.on(transak.ALL_EVENTS, (data: any) => {
      if (appEnv === 'testnet') {
        console.log(data)
      }
    })

    transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
      transak.close()
      this.$emit('close')
    })

    // This will trigger when the user marks payment is made.
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData: any) => {
      if (appEnv === 'testnet') {
        console.log(orderData)
      }
      transak.close()
      this.$emit('close')
    })
  }
}
