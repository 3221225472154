














































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mdiAlert, mdiInformation } from '@mdi/js'
import type { Currency, Network } from '@/types'
import { Conf } from '@/models/Conf'
import { CustodialAddressStatus, getDepositAddress } from '@/clients/cpinblocks'

@Component({
	components: {
	},
})
export default class DepositInformation extends Vue {
	@Prop() currency!: Currency
	@Prop() conf!: Conf

	custodialAddressStatus: CustodialAddressStatus | null = null
	custodialWatcher!: number
	depositAddress: string | null = null
	mdiAlert = mdiAlert
	mdiInformation = mdiInformation
  // FIXME we force USDC on ARBITRUM as a temporary path to test Dinari on-chain integration
	network: Network = this.currency === 'USDC' ? 'ARBITRUM' : 'BINANCE'

	@Watch('depositAddress')
	onDepositAddressChanged (value: string): void {
		if (value) {
			clearInterval(this.custodialWatcher)
		}
	}

	get bcChainId (): string {
		for (const bc of this.conf.blockchains) {
			if (this.network === bc.network) {
				return bc.chain.toString()
			}
		}
		return 'N/A'
	}

	get bcName (): string {
		for (const bc of this.conf.blockchains) {
			if (this.network === bc.network) {
				return bc.name
			}
		}
		return 'N/A'
	}

	get loading () {
		return this.depositAddress === null
	}

	get tokenURL (): string {
		for (const bc of this.conf.blockchains) {
			if (bc.network === this.network) {
				for (const contract of bc.contracts) {
					if (contract.symbol === this.currency) {
						return bc.scanUrl + '/address/' + contract.address
					}
				}
			}
		}
		return 'N/A'
	}

	async mounted (): Promise<void> {
		this.custodialAddressStatus = await getDepositAddress(this.$store.state.jwt, this.currency, this.network)
		if (this.custodialAddressStatus?.status === 'OK') {
			this.depositAddress = this.custodialAddressStatus.address ?? null
		} else {
			const interval = 1000 // 1s
			this.custodialWatcher = window.setInterval(async () => {
				this.custodialAddressStatus = await getDepositAddress(this.$store.state.jwt, this.currency, this.network)
				if (this.custodialAddressStatus?.address && this.custodialAddressStatus.status === 'OK') {
					this.depositAddress = this.custodialAddressStatus?.address
				}
			}, interval)
		}
	}

	beforeDestroy (): void {
		window.clearInterval(this.custodialWatcher)
	}

	async copy (text: string): Promise<void> {
		await navigator.clipboard.writeText(text)
	}
}
