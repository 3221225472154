
























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
	components: {},
})

export default class ModalNotification extends Vue {
	@Prop() show = false
	@Prop() text!: string

	close (): void {
		this.$emit('close')
	}
}
