




































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getAccounts, getAPIConfiguration, transferTokens } from '@/clients/cpinblocks'
import { Account } from '@/models/Account'
import { Conf } from '@/models/Conf'
import Code2FADialog from '@/components/Code2FADialog.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { alertRawError, convertValueToBigNumber, formatFixedDecimals } from '@/utils'
import type { Currency, TokenType } from '@/types'
import { ValidationRules } from '@/types'
import { BigNumber } from 'bignumber.js'
import { TranslateResult } from 'vue-i18n'

@Component({
	components: {
		CurrencyInput,
		Code2FADialog,
	},
})
export default class AddInternalTransfer extends Vue {
	@Prop() currency!: Currency
	@Prop() type!: TokenType
	private isValid = false
	private show2FADialog = false
	private conf: Conf | null = null
	private quantityPrecision = 2
	private quantityMax: BigNumber = new BigNumber('0')
	private sellerAccounts: Account[] = []
	private sellerAccountIdentifier = ''
	private creating = false
	private loading = true
	private quantity = new BigNumber('0')
	private recipientId = ''

	get currencyQuantityInputLabel (): TranslateResult {
		return this.$t('addOffer.quantitySend', { currency: this.currency })
	}

	get modalTitle (): TranslateResult {
		return this.$t('ftmpAddOffer.transferTitle')
	}

	get ibexIdLabel (): TranslateResult {
		return this.$t('ftmpAddOffer.recipientId')
	}

	private get rules (): ValidationRules {
		return {
			required: [
				(v?: string) => !!v || this.$t('rule.requiredField'),
			],
			quantity: [
				(v?: string) => !!v || this.$t('rule.requiredField'),
				(v: string) => convertValueToBigNumber(v).gt(0) || this.$t('ftmpOffer.rule.min', { min: 0 }),
				(v: string) => !convertValueToBigNumber(v).isNaN() || this.$t('rule.requiredField'),
				(v: string) => {
					return convertValueToBigNumber(v).isLessThanOrEqualTo(this.quantityMax) || this.$t('ftmpOffer.rule.max', {
						max: formatFixedDecimals(this.$i18n, this.quantityMax, this.quantityPrecision),
					})
				},
			],
			buyerId: [
				(v: string) => (!!v && v.length === 8) || this.$t('ftmpAddOffer.buyerIdLengthRequired'),
			],
		}
	}

	onUpdateQuantity (n: BigNumber): void {
		this.quantity = n
		this.validateForm()
	}

	async created (): Promise<void> {
		await this.reset()
		this.loading = false
	}

	async cancel (): Promise<void> {
		this.$emit('cancel')
	}

	async open2FADialog (): Promise<void> {
		this.creating = true
		this.show2FADialog = true
	}

	async createOffer (object: any): Promise<void> {
		const code2FA = object.code
		this.show2FADialog = false
		this.creating = true
		const offerToTransfer = {
			currency: this.currency,
			type: this.type,
			amount: this.quantity,
			ownerIdTo: this.recipientId,
		}
		try {
			await transferTokens(this.$store.state.jwt, offerToTransfer, code2FA)
			this.creating = false
			this.$emit('success')
		} catch (error: any) {
			alertRawError(error.message)
			this.creating = false
			this.$emit('fail')
		}
	}

	async reset (): Promise<void> {
		this.loading = true
		this.conf = await getAPIConfiguration()
		this.sellerAccounts = await getAccounts(this.$store.state.jwt, false)
		for (const i in this.sellerAccounts) {
			if (this.sellerAccounts[i].currency === this.currency && this.sellerAccounts[i].type === this.type) {
				this.sellerAccountIdentifier = this.sellerAccounts[i].id
				this.sellerAccounts[i].name = this.currency + (this.type !== 'MAIN' ? ' ' + this.type : '') + ' (' + this.sellerAccounts[i].balance + ')'
				this.quantityMax = this.sellerAccounts[i].balance
			}
		}
		this.loading = false
		setTimeout(this.validateForm, 200)
	}

	validateForm (): void {
		if (!this.loading) {
			(this.$refs.form as Vue & { validate: () => boolean }).validate()
		}
	}

	onKeyPressBuyerID (e: KeyboardEvent): void {
		if (!e.key.match(/^[a-z0-9]$/)) {
			e.preventDefault()
		}
	}
}
